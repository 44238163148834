/*
https://github.com/tomchentw/react-google-maps/issues/764#issuecomment-442163940
*/
.g-map > div {
	background-color: transparent !important;
}

.map-height {
	height: calc(60vh - 53px);
}

.pv05 {
	padding-top: 0.125rem;
	padding-bottom: 0.125rem;
}

.shadow {
	box-shadow: rgba(66, 66, 66, 0.5) 0px 0.5rem 1rem 0px;
}

@media screen and (min-width: 60rem) {
	.mw5-5-l {
		max-width: 24rem;
	}

	.map-height {
		height: 100%;
	}
}
